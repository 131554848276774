<template>
  <div id="structure-page">
    <modal-delete
      :level="currentLevel"
      :name="currentName"
      @confirm="deleteItems"
    />
    <modal-add-edit
      v-if="device !== 'mobile'"
      :modalTitle="modalTitle"
      :currentId="currentId"
      :customFields="siteCustomFields"
      @clearEditInfos="clearEditInfos()"
      @closeModal="closeModalAddEdit()"
      @addedItem="getStructures()"
    />
    <modal-group
      :modalTitle="modalTitle"
      :currentId="currentId"
      @addedItem="getStructures()"
    />
    <modal-work-center
      :modalTitle="modalTitle"
      :currentId="currentId"
      :customFields="workCenterCustomFields"
      @addedItem="getStructures()"
    />
    <modal-people
      :modalTitle="modalPeopleName"
      :currentId="currentId"
      @updateDragList="updateDragList"
    />
    <modal-resource
      :modalTitle="modalPeopleName"
      :currentId="currentId"
      @updateDragList="updateDragList"
    />
    <div class="header-block">
      <div class="first-line">
        <div class="title">
          {{ $t('PagesViews.OrganizationalStructure') }}
        </div>
        <div
          v-if="device !== 'mobile'"
          class="add-structure"
        >
          <b-button
            class="btn-new-structure"
            @click="changeModalName('new')"
          >
            <AddIcon class="add-icon" />
            {{ $t('StructurePage.NewUnit') }}
          </b-button>
        </div>
      </div>
      <div class="second-line">
        <div class="search-input">
          <div class="div-custom-input input__structures">
            <div class="icon-block">
              <SearchIcon class="search-icon" />
            </div>
            <b-form-tags
              class="input-search-structures"
              ref="formTagStructures"
              v-model="filter.textFilter"
              duplicate-tag-text=""
              :placeholder="filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''"
              @input="getStructures()"
              remove-on-delete
              add-on-change
            />
          </div>
        </div>
      </div>
    </div>
    <div class="body-block">
      <div
        v-if="dragList.length === 0 && !noItemsFound"
        class="h-100"
      >
        <div class="no-items h-100">
          <EmptyBoxIcon class="empty-box" />
          <p class="title">{{ $t('StructurePage.NoUnitsTitle') }}</p>
          <p class="subtitle">{{ $t('StructurePage.NoUnitsSubtitle') }}</p>
          <b-button
            v-if="device !== 'mobile'"
            class="btn-new-department"
            @click="changeModalName('new')"
          >
            <AddIcon class="add-icon" />
            {{ $t('StructurePage.NewUnit') }}
          </b-button>
        </div>
      </div>
      <CollapseDraggable
        v-else
        isStructure
        :list="dragList"
        :dragList="dragList"
        :dragListStart="dragListStart"
        :optionIcons="optionIcons"
        :levelIcons="levelIcons"
        :selectedChecks="selectedChecks"
        :rolesList="rolesList"
        :shouldUpdate="shouldUpdate"
        :forceRender="forceRender"
        @selectedToZero="UPDATE_SELECTED_CHECKS(0)"
        @deleteItems="(ids) => deleteItems(ids)"
        @updatedList="updatedList"
        @getEditInfos="(id, level) => getEditInfos(id, level)"
        @checkedCounter="checkedCounter"
        @undoChangesTree="undoChangesTree"
        @saveActualTree="saveActualTree"
      />
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import { BFormTags, BButton, BCol } from 'bootstrap-vue';
  import ModalAddEdit from './components/ModalAddEdit.vue';
  import ModalDelete from './components/ModalDelete.vue';
  import CollapseDraggable from '@/@core/components/collapse-draggable/CollapseDraggable.vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
  import ModalGroup from '../organizationalStructure/components/ModalGroup.vue';
  import ModalWorkCenter from '../organizationalStructure/components/ModalWorkCenter.vue';
  import ModalPeople from '../organizationalStructure/components/peopleModals/ModalPeople.vue';
  import ModalResource from '../organizationalStructure/components/resourceModals/ModalResource.vue';

  import EmptyBoxIcon from '@core/assets/icons/empty-box-icon.svg';
  import GroupIcon from '@core/assets/icons/department-icon.svg';
  import SubDepartmentIcon from '@core/assets/icons/subdepartment-icon.svg';
  import InfoIcon from '@core/assets/icons/info-outline-icon.svg';
  import PeopleIcon from '@core/assets/icons/people-icon.svg';
  import ResourceIcon from '@core/assets/icons/resource-icon.svg';
  import OptionsIcon from '@core/assets/icons/options-icon.svg';
  import DeleteIcon from '@core/assets/icons/delete-icon.svg';
  import EditIcon from '@core/assets/icons/edit-icon.svg';
  import NewIcon from '@core/assets/icons/new-icon.svg';
  import WorkCenterIcon from '@core/assets/icons/workcenter-icon.svg';
  import UnitIcon from '@core/assets/icons/unit-icon.svg';

  export default {
    components: {
      BFormTags,
      BButton,
      BCol,
      ModalAddEdit,
      ModalDelete,
      CollapseDraggable,
      ModalGroup,
      ModalWorkCenter,
      ModalPeople,
      ModalResource,
      EmptyBoxIcon,
      GroupIcon,
      SubDepartmentIcon,
      InfoIcon,
      PeopleIcon,
      ResourceIcon,
      OptionsIcon,
      DeleteIcon,
      EditIcon,
      NewIcon,
      WorkCenterIcon,
      UnitIcon,
      AddIcon: () => import('@core/assets/icons/add-icon.svg'),
      SearchIcon: () => import('@core/assets/icons/search-icon.svg'),
      EmptyBoxIcon: () => import('@core/assets/icons/empty-box-icon.svg')
    },
    data() {
      return {
        filter: {
          textFilter: []
        },
        forceRender: 0,
        noItemsFound: false,
        modalPeopleName: '',
        currentLevel: null,
        currentId: null,
        currentName: null,
        shouldUpdate: false,
        workCenterCustomFields: [],
        siteCustomFields: [],
        prefix: localStorage.getItem('prefix'),
        levelIcons: [
          {
            title: 'StructurePage.Unit',
            techName: 'Unit',
            level: 1,
            icon: UnitIcon
          },
          {
            title: 'StructurePage.Group',
            techName: 'Group',
            level: 2,
            icon: GroupIcon
          },
          {
            title: 'StructurePage.WorkCenter',
            techName: 'WorkCenter',
            level: 3,
            icon: WorkCenterIcon
          }
        ],
        optionIcons: [
          {
            title: 'WorkcenterList.Resources',
            showOnLast: 3,
            icon: ResourceIcon,
            click: (el) => {
              this.currentId = el.id;
              this.modalPeopleName = el.name;
              this.UPDATE_MODAL_RESOURCE_TOGGLE(true);
            }
          },
          {
            title: 'DepartmentPage.People',
            showOnLast: 3,
            icon: PeopleIcon,
            click: (el) => {
              this.currentId = el.id;
              this.modalPeopleName = el.name;
              this.UPDATE_MODAL_PEOPLE_TOGGLE(true);
            }
          },
          {
            title: 'Info',
            icon: InfoIcon,
            click: () => {}
          },
          {
            title: 'DepartmentPage.Actions',
            icon: OptionsIcon,
            removeOnMobile: true,
            click: () => {},
            actions: [
              {
                title: 'DepartmentPage.New',
                icon: NewIcon,
                click: (e) => {
                  const levelModal = this.levelIcons.find((e) => e.level === this.currentLevel + 1);
                  this.UPDATE_MODAL_TITLE(`${this.$t(e.title)} ${this.$t(levelModal.title)}`);
                  if (levelModal.techName === 'Group') {
                    this.UPDATE_MODAL_GROUP_TOGGLE(true);
                  }
                  if (levelModal.techName === 'WorkCenter') {
                    this.UPDATE_MODAL_WORKCENTER_TOGGLE(true);
                  }
                }
              },
              {
                title: 'DepartmentPage.Edit',
                icon: EditIcon,
                click: (e) => {
                  const nameLevel = this.levelIcons.find((e) => e.level === this.currentLevel);
                  const editTitle = `${this.$t(e.title)} ${this.$t(nameLevel.title)}`;
                  this.UPDATE_MODAL_TITLE(editTitle);
                  if (nameLevel.techName === 'Unit') {
                    this.UPDATE_MODAL_ADD_EDIT_TOGGLE(true);
                  }
                  if (nameLevel.techName === 'Group') {
                    this.UPDATE_MODAL_GROUP_TOGGLE(true);
                  }
                  if (nameLevel.techName === 'WorkCenter') {
                    this.UPDATE_MODAL_WORKCENTER_TOGGLE(true);
                  }
                }
              },
              {
                title: 'DepartmentPage.Delete',
                icon: DeleteIcon,
                click: () => {
                  this.currentName = this.getCurrentName(this.dragList);
                  this.UPDATE_MODAL_DELETE(true);
                }
              }
            ]
          }
        ],
        device:
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile'
      };
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    async mounted() {
      const vm = this;
      this.activeCarousel();
      await this.$http
        .get(`/api/${vm.prefix}/myconfig/organizational-structure/context`)
        .then((response) => {
          this.UPDATE_ROLES_LIST(
            response.data.data.functions.map((item) => ({
              ...item,
              name: item.name
            }))
          );
          this.siteCustomFields = response.data.data.industrial_parameters_site.map((e) => ({
            ...e,
            industrialStructParameterType: e.industrial_struct_parameter_type,
            industrialStructParameterOption: e.industrial_struct_parameter_option
          }));
          this.workCenterCustomFields = response.data.data.industrial_parameters_workcenter.map(
            (e) => ({
              ...e,
              industrialStructParameterType: e.industrial_struct_parameter_type,
              industrialStructParameterOption: e.industrial_struct_parameter_option
            })
          );
        })
        .catch((error) => console.error(error));
      this.getStructures();
      this.dragList.map((e) => {
        this.customFields = e.industrial_parameter;
      });
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('departments', ['UPDATE_MODAL_DELETE_SNACK_BAR']),
      ...mapMutations('structures', [
        'UPDATE_MODAL_ADD_EDIT_TOGGLE',
        'UPDATE_MODAL_TITLE',
        'UPDATE_DRAG_LIST',
        'UPDATE_DRAG_LIST_START',
        'UPDATE_SELECTED_CHECKS',
        'UPDATE_MODAL_DELETE',
        'UPDATE_MODAL_GROUP_TOGGLE',
        'UPDATE_MODAL_WORKCENTER_TOGGLE',
        'UPDATE_MODAL_PEOPLE_TOGGLE',
        'UPDATE_MODAL_RESOURCE_TOGGLE',
        'UPDATE_ROLES_LIST',
        'UPDATE_PEOPLE_LIST',
        'UPDATE_RESOURCE_LIST',
        'UPDATE_RESOURCE_TO_LINK_LIST'
      ]),
      handleResize() {
        this.device =
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile';
      },
      activeCarousel() {
        const vm = this;
        $(function () {
          document
            .getElementsByClassName('input-search-structures')[0]
            .setAttribute('tabIndex', '-1');

          const slider = document.querySelector('.b-form-tags-list');
          let mouseDown = false;
          let startX, scrollLeft;

          let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          };
          let stopDragging = function () {
            mouseDown = false;
          };

          slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) {
              return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
          });

          slider.addEventListener('mousedown', startDragging, false);
          slider.addEventListener('mouseup', stopDragging, false);
          slider.addEventListener('mouseleave', stopDragging, false);
        });
      },
      changeModalName(name) {
        this.UPDATE_MODAL_ADD_EDIT_TOGGLE(true);
        if (name === 'new') {
          this.UPDATE_MODAL_TITLE('StructurePage.NewUnit');
          this.currentId = null;
        }
        if (name === 'edit') {
          this.UPDATE_MODAL_TITLE('StructurePage.EditUnit');
        }
      },
      updateDragList() {
        this.getStructures();
      },
      async getStructures() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        const textFilterQuery = this.filter.textFilter
          .map((filter) => `${filter}`)
          .join('&text_filter=');
        await this.$http
          .get(
            `/api/${this.prefix}/myconfig/organizational-structure?text_filter=${textFilterQuery}`
          )
          .then((response) => {
            this.noItemsFound = !!textFilterQuery.length;
            const updatedArr = response.data.data;
            this.forceRender = this.forceRender + 1;
            this.addChildWhenUndefined(updatedArr);
            this.addLevelByChild(updatedArr);
            this.UPDATE_DRAG_LIST(updatedArr);
            this.UPDATE_SELECTED_CHECKS(0);
            this.saveActualTree();
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      addLevelByChild(arr, level = 1) {
        arr.map((e) => {
          e.level = level;
          if (e.child.length > 0) {
            e.child.forEach((f) => (f.parentId = e.id));
          }
          if (e.child.length) this.addLevelByChild(e.child, level + 1);
        });
      },
      addChildWhenUndefined(objArr) {
        objArr.forEach((obj) => {
          if (!obj.hasOwnProperty('child')) {
            obj.child = [];
            obj.isWorkcenter = true;
          }
          this.addChildWhenUndefined(obj.child);
        });
      },
      getEditInfos(id, level) {
        this.currentId = id;
        this.currentLevel = level;
      },
      clearEditInfos() {
        this.currentId = null;
        this.currentLevel = null;
      },
      closeModalAddEdit() {
        this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      findLevelById(array, targetId) {
        for (const obj of array) {
          if (obj.child && obj.child.some((child) => child.id === targetId)) {
            return obj.level + 1;
          }
          const nestedParentId = this.findParentId(obj.child || [], targetId);
          if (nestedParentId) {
            return nestedParentId;
          }
        }
        return 1;
      },
      async updatedList(_, ids, destinyId) {
        this.allCheckedItems = [];
        this.getAllCheckedItems(this.dragList);
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        const parentId = await this.findParentId(this.dragList, Number(destinyId));
        const parentLevel = this.findLevelById(this.dragList, parentId);

        const payload = {
          destiny: {
            id: parentId,
            level: parentLevel
          },
          elements: ids
        };

        await this.$http
          .put(`/api/${this.prefix}/myconfig/organizational-structure/movimentation`, payload)
          .then(() => {
            this.unCheckAll(this.dragList);
            this.getStructures();
          })
          .catch((error) => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
            error.response.data.errors.map((err) => {
              this.showToast(this.$t('Error'), 'FrownIcon', err.message, 'danger');
            });
          });
      },
      findParentId(array, targetId) {
        for (const obj of array) {
          if (obj.child && obj.child.some((child) => child.id === targetId)) {
            return obj.id;
          }
          const nestedParentId = this.findParentId(obj.child || [], targetId);
          if (nestedParentId) {
            return nestedParentId;
          }
        }
        return null;
      },
      unCheckAll(list) {
        for (const obj of list) {
          obj['checked'] = false;
          if (obj.child && obj.child.length > 0) {
            this.unCheckAll(obj.child);
          }
        }
        this.UPDATE_SELECTED_CHECKS(0);
      },
      getAllCheckedItems(list) {
        list.map((e) => {
          if (e.checked) {
            this.allCheckedItems.push(e.id);
          }
          if (e.child) this.getAllCheckedItems(e.child);
        });
      },
      getCurrentName(list) {
        if (!list || list.length === 0) {
          return null;
        }

        for (const item of list) {
          if (item.id === this.currentId) {
            return item.name;
          }

          if (!item.isWorkcenter && item.child && item.child.length > 0) {
            const foundItem = this.getCurrentName(item.child);
            if (foundItem) {
              return foundItem;
            }
          }
        }

        return null;
      },

      async deleteItems(ids) {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        const payload = {
          ids: ids ? ids : [{ level: this.currentLevel, id: this.currentId }]
        };
        let toastText = '';

        if (ids) {
          toastText = this.$t('StructurePage.ItemsDeleted');
        } else {
          switch (this.currentLevel) {
            case 1:
              toastText = this.$t('StructurePage.UnitDeleted');
              break;
            case 2:
              toastText = this.$t('StructurePage.GroupDeleted');
              break;
            case 3:
              toastText = this.$t('StructurePage.WorkCenterDeleted');
              break;

            default:
              break;
          }
        }

        await this.$http
          .delete(`/api/${this.prefix}/myconfig/site`, { data: payload })
          .then(() => {
            this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon', toastText, 'success');
            this.shouldUpdate = !this.shouldUpdate;
            this.UPDATE_MODAL_DELETE(false);
            this.UPDATE_MODAL_DELETE_SNACK_BAR(false);
            this.UPDATE_SELECTED_CHECKS(0);
            this.getStructures();
          })
          .catch((error) => {
            this.showToast(this.$t('Error'), 'FrownIcon', error.response.data.message, 'danger');
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      checkedCounter(counter) {
        this.UPDATE_SELECTED_CHECKS(counter);
      },
      undoChangesTree() {
        this.showToast(
          this.$t('Error'),
          'FrownIcon',
          this.$t('StructurePage.ErrorToastText2'),
          'danger'
        );
        this.UPDATE_DRAG_LIST(JSON.parse(JSON.stringify(this.dragListStart)));
      },
      saveActualTree() {
        this.UPDATE_DRAG_LIST_START(JSON.parse(JSON.stringify(this.dragList)));
      }
    },
    computed: {
      ...mapState('structures', {
        modalTitle: 'modalTitle',
        isModalAddEditOpen: 'isModalAddEditOpen',
        dragList: 'dragList',
        dragListStart: 'dragListStart',
        selectedChecks: 'selectedChecks',
        rolesList: 'rolesList'
      })
    }
  };
</script>
<style lang="scss">
  #structure-page {
    padding: 16px 0;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

    @media (max-width: 768px) {
      margin-top: 24px;
    }

    .header-block {
      padding: 0 16px;

      .first-line {
        display: flex;
        justify-content: space-between;

        .title {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }

        .add-structure {
          .btn-new-structure {
            border-color: #974900 !important;
            background-color: #974900 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100% !important;
            min-width: 136px;
            padding: 3px 14px !important;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.0125em;

            .add-icon {
              width: 12px;
              height: 12px;
              fill: #fff;
            }
          }
        }
      }

      .second-line {
        .search-input {
          display: flex;
          align-items: center;
          padding: 16px 0 8px;

          .div-custom-input {
            display: flex;
            align-items: center;
            border-radius: 4px;
            width: 100%;
            overflow: hidden;
            border: 1px solid #cfc4be;

            .icon-block {
              display: flex;
              align-items: center;
              padding-left: 14px;
              height: 14px;

              .search-icon {
                width: 14px;
                height: 14px;
                fill: #cfc4be;
              }
            }

            .input-search-structures {
              width: 100% !important;
              border: none;
              overflow: hidden;

              &.focus {
                box-shadow: none;
              }

              &.b-form-tags {
                padding: 0 !important;
                display: flex;
                align-items: center !important;

                .b-form-tags-list {
                  width: 100% !important;
                  margin-left: 14px !important;
                  margin-top: 0;
                  height: 100% !important;
                  flex-wrap: nowrap !important;
                  display: flex !important;
                  align-items: center !important;
                  overflow: scroll;
                  overflow-y: hidden;
                  cursor: grabbing;
                  cursor: -webkit-grabbing;

                  &::-webkit-scrollbar {
                    display: none !important;
                  }

                  .b-form-tags-field {
                    margin: 9px 0;
                  }
                }
              }

              .b-form-tags-button {
                display: none;
              }

              .b-form-tag {
                background-color: #974900;
                margin-right: 0.6rem;
                padding: 2px 8px !important;
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    .body-block {
      .no-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        margin: 0 16px;
        border: 1px solid #cfc4be;

        .empty-box {
          width: 80px;
          height: 80px;
        }

        .title {
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
          color: #4c4541;
          margin: 0;
        }

        .subtitle {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #998f8a;
          padding: 4px 0 12px;
          margin: 0;
        }

        .btn-new-department {
          border-color: #974900 !important;
          background-color: #974900 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 8px 21px;
          line-height: 20px;
          letter-spacing: 0.175px;

          .add-icon {
            width: 14px;
            height: 14px;
            fill: #fff;
          }
        }

        @media (max-width: 480px) {
          .empty-box {
            width: 40px;
            height: 40px;
          }

          .title {
            font-size: 14px;
          }

          .subtitle {
            font-size: 12px;
            text-align: center;
            padding: 4px 48px 12px;
            line-height: 16px;
          }

          .btn-new-department {
            padding: 4px 14px;
          }
        }
      }

      &:has(.block-no-results) {
        height: calc(100% - 90px);
      }

      &:has(.no-items) {
        height: calc(100% - 90px);
      }
    }

    &:has(.body-block .block-no-results) {
      height: calc(100vh - 135px);
    }

    &:has(.body-block .no-items) {
      height: calc(100vh - 135px);
    }
  }
</style>
